import React from "react";

import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";

const Branches = () => {
  //   ✅ঢাকা অফিসঃ
  // ১। মিরপুর প্রধান অফিস - খান প্লাজা(৭ম তালা), সড়ক নং-১, মিরপুর-১০, ঢাকা-১২১৬।
  // ফোন নংঃ ০১৯৭৩ ২৫২ ৫৬৬ এবং ০১৪০১ ৪৫৭ ৯৯১
  // ২। শান্তিনগর অফিস - কর্ণফুলী গার্ডেন সিটি, রুম নং-২০, ৪র্থ তালা, ১০৯-কাকরাইল, ঢাকা- ১০০০। ফোন নংঃ ০১৭০৩ ৮৫৬ ৫৩৩ এবং ০১৯২৫ ৯২৩ ৯৭০
  // ৩। শনির আখড়া অফিস -  বায়তুন নুর জামে মসজিদ এর বিপরীত পাশের বিল্ডিং (২য় তালা) কাচ্চি ভাই রোড, নুরপুর,শনির আখড়া, যাত্রাবাড়ী,ঢাকা ১২৩৬
  // মোবাইল নং: ০১৬২২ ৯৭৩ ৩৩৪ এবং ০১৫৭১ ২৩৬ ২৭৩
  // ৪। সাইনবোর্ড  ঢাকা অফিস -  ২৫৪/১, হাজী সোনামিয়া মার্কেট (৪র্থ তলা), উত্তর সাইনবোর্ড, ( নারায়ণগঞ্জ জেলা সমিতি ভবন), ডেমরা ,ঢাকা.ফোনঃ ০১৭৭৬ ৯১৯ ৬২১
  // ✅ সাভার অফিসঃ মফিজ প্লাজা (৩য় তালা), সিটি সেন্টারের পিছনে, সোবহানবাগ, সাভার।
  // মোবাইলঃ ০১৭০৩ ৮৫৬ ৫৩৩ এবং ০১৪০১ ৪৫৭ ৯৯৩
  // ✅চট্টগ্রাম অফিসঃ আলী টাওয়ার, বাকলিয়া এক্সেস রোড়, বাকলিয়া, চট্টগ্রাম।
  // ফোন:- ০১৮৮৮ ৫১০০১২ এবং ০১৩০৬ ২৬১০৭০
  // ✅ কুমিল্লা অফিসঃ
  // ১। হোল্ডিং নং-৪০০, ব্লক- এ, ডিগ্রি কলেজ রোড, ধর্মপুর, কুমিল্লা।
  // (মেডি হেলথ মেডিকেল সেন্টারের উত্তর পাশের বিল্ডিং)
  // মোবাইলঃ ০১৬২৭০০৯২৩৯
  // ২। কুমিল্লা গৌরীপুর অফিসঃ আনু মিয়া টাওয়ার(প্রথমতলা -ইসলামী ব্যাংকের নিচে), গৌরীপুর বাজার, দাউদকান্দি, কুমিল্লা।
  // ফোন নং- ০১৯৭৫ ৭০৭ ০৭১ , ০১৮১৪ ২৪২ ৪২২, ০১৭৬৫ ১৩৩ ৩২৮
  // ✅কক্সবাজার অফিসঃ ১১৬৯, মনির ম্যানশন(৩য় তালা), কালুর দোকান, টেকপাড়া, কক্সবাজার।
  // ফোন নংঃ ০১৯৮৬ ৮৩২ ৪২৪ এবং ০১৯৫৭ ৩৫৫ ২৬২
  // ✅রংপুর অফিসঃ বাড়ি নংঃ আলম টাওয়ার (৫ম তলা) ,কলেজ রোড,বীকন মোড়,রংপুর
  // ফোন নংঃ ০১৩২৩ ৬৬৭ ৫০৮ এবং ০১৬৪১ ১৭৮ ৬৭৪
  // ✅ নরসিংদী অফিসঃ
  // নরসিংদী অফিস- ২০৬/১০ পশ্চিম ব্রাহ্মন্দী (বালুচর), নরসিংদী সদর, নরসিংদী। ফোনঃ ০১৯২৯১২৪১৩৩, ০১৫১৫৬১৩৪৮১
  // ✅ চাঁদপুর অফিসঃ ২২২/১, ভূঁইয়া ফ্ল্যাট, ৫ম তলা, ওয়ারলেস বাজার (ইকরা মডেল একাডেমীর পশ্চিম পাশে)  চাঁদপুর পৈরসভা,চাঁদপুর-৩৬00
  // মোবাইলঃ ০১৭৭৬ ৯১৯ ৬২১
  // ✅ রাজশাহী অফিসঃ ৫ম তালা, গনকপারা, ঘোড়ামাড়া, রাজশাহী। হোটেল নাইস ইন্টারনাল এর উত্তর দিকে।
  // ফোনঃ ০১৭১১ ১৩৮ ৫৯৩ এবং ০১৯১১ ৯৭১ ৪৬২
  // ✅ ভোলা অফিসঃ ছালাম মিয়াজীর বাসা, আইডিয়াল স্কুল রোড, পৌড় ৪নং ওয়ার্ড, বোরহানউদ্দিন, ভোলা।
  // ফোনঃ ০১৭১২ ৪৪৬ ৯৫০
  // ✅ গাজীপুর অফিসঃমুন্সী কেরামত আলী কমপ্লেক্স (৪র্থ তলা), জয়দেবপুর রোড, চান্দনা চৌরাস্তা, গাজীপুর-১২০২
  // ফোনঃ ০১৬৩১০৮৩৬৮৩ এবং ০১৭৪৭১১০৭৮১
  return (
    <>
      <hr></hr>
      <section className="all-company-feature frame-bg mtp" id="branches">
        <Container>
          <Row className="mb-4">
            <Col md={12} className="text-center mb-3">
              <h2 className="mb-4">Our Branches</h2>
            </Col>
          </Row>
          <Row className="mb-4 mt-3">
            <Col md={4} sm={12} className=" branch-bg-color p-3">
              <h3 className="mb-0">Dhaka 1 (Main)</h3>
              <p className="branch-name-text mb-0">Mirpur Head Office,</p>{" "}
              <p className="mb-0">
                <span className="fw-bold">Phone:</span> 01973252566 ,
                01401457991{" "}
              </p>{" "}
              <p className="mb-0">
                {" "}
                <span className="fw-bold">Address:</span> Khan Plaza (7th
                Floor),
              </p>
              <p1> Road-01,Mirpur-10,Dhaka-1216</p1>
            </Col>
            <Col md={4} sm={12} className="p-3">
              <h3 className="mb-0">Dhaka 2</h3>
              <p className="branch-name-text mb-0">Shantinogor Office,</p>{" "}
              <p className="mb-0">
                <span className="fw-bold">Phone:</span> 01703856533 ,
                01925923970
              </p>
              <p>
                <span className="fw-bold">Address:</span>
                Karnufuli Garden City,Room-20,4th Floor,109-Kakrail,Dhaka
              </p>
            </Col>
            <Col md={4} sm={12} className="p-3 branch-bg-color">
              <h3 className="mb-0">Dhaka 3</h3>
              <p className="branch-name-text mb-0">
                Shonir Akhra Office ,
              </p>{" "}
              <p className="mb-0">
                <span className="fw-bold">Phone:</span> +8801622973334 ,
                +8801571236273
              </p>
              <p>
                <span className="fw-bold">Address:</span>
                opposite of Baitun Nur Jame Mosjid (1st floor) Nurpur ( kachhi
                Bhai Road) Shonir Akhra, Jatrabari, Dhaka 1236{" "}
              </p>
            </Col>
            <Col md={4} sm={12} className="p-3 ">
              <h3 className="mb-0">Dhaka 4</h3>
              {/* ২৫৪/১, হাজী সোনামিয়া মার্কেট (৪র্থ তলা), উত্তর সাইনবোর্ড, (
              নারায়ণগঞ্জ জেলা সমিতি ভবন), ডেমরা ,ঢাকা ফোনঃ ০১৭৭৬ ৯১৯ ৬২১ */}
              <p className="branch-name-text mb-0">SignBoard Office ,</p>{" "}
              <p className="mb-0">
                <span className="fw-bold">Phone:</span> 01776919621
              </p>
              <p>
                <span className="fw-bold">Address:</span>
                254/1, Haji Sonamia Market (4th floor), Uttara SignBoard,
                (Narayanganj zilla Somiti Building), Demra, Dhaka
              </p>
            </Col>
            <Col md={4} sm={12} className="p-3 branch-bg-color">
              <h3 className="mb-0">Gazipur</h3>
              {/* গাজীপুর অফিসঃমুন্সী কেরামত আলী কমপ্লেক্স (৪র্থ তলা), জয়দেবপুর রোড, চান্দনা চৌরাস্তা, গাজীপুর-১২০২
              ফোনঃ ০১৬৩১০৮৩৬৮৩ এবং ০১৭৪৭১১০৭৮১ */}
              <p className="branch-name-text mb-0">Gazipur Office ,</p>{" "}
              <p className="mb-0">
                <span className="fw-bold">Phone:</span> 01631083683, 01747110781
              </p>
              <p>
                <span className="fw-bold">Address:</span>
                Munshi Keramot Ali Complex (4th floor), Joydebpur road, Chandana
                Bazar, Gazipur-1202
              </p>
            </Col>
            <Col md={4} sm={12} className="p-3">
              <h3 className="mb-0">Savar Office</h3>
              {/* <p className="branch-name-text mb-0">Shantinogor Office ,</p>{" "} */}
              <p className="mb-0">
                <span className="fw-bold">Phone:</span> 01703856533 ,
                01401457993
              </p>
              <p>
                <span className="fw-bold">Address:</span>
                Mofiz Plaza, 3rd Floor, Behind city centre, Sobhanbag, Savar.
              </p>
            </Col>
            <Col md={4} sm={12} className="p-3 branch-bg-color">
              <h3 className="mb-0">Chottogram Office</h3>
              {/* <p className="branch-name-text mb-0">Shantinogor Office ,</p>{" "} */}
              {/* আলী টাওয়ার, বাকলিয়া এক্সেস রোড়, বাকলিয়া, চট্টগ্রাম।
ফোন:- ০১৮৮৮ ৫১০০১২ এবং ০১৩০৬ ২৬১০৭০ */}
              <p className="mb-0">
                <span className="fw-bold">Phone:</span> 01888510012 ,
                01306261070
              </p>
              <p>
                <span className="fw-bold">Address:</span>
                Ali Tower,Bakalia Access Road, Bakalia Chottogram.
              </p>
            </Col>
            <Col md={4} sm={12} className="p-3">
              <h3 className="mb-0">𝗖u𝗺𝗶𝗹𝗹𝗮 𝗢𝗳𝗳𝗶𝗰𝗲 1 (City)</h3>
              {/* <p className="branch-name-text mb-0">Shantinogor Office ,</p>{" "} */}
              <p className="mb-0">
                <span className="fw-bold">Phone:</span> 01627 009 239 ,
              </p>
              <p>
                <span className="fw-bold">Address:</span>
                Holding No: 400, Block -A , Degree College Road, Dhormopur,
                Cumilla.
              </p>
            </Col>
            <Col md={4} sm={12} className="p-3 branch-bg-color">
              <h3 className="mb-0">𝗖u𝗺𝗶𝗹𝗹𝗮 𝗢𝗳𝗳𝗶𝗰𝗲 2 (𝗚𝗼𝘂𝗿𝗶𝗽𝘂𝗿)</h3>
              {/* <p className="branch-name-text mb-0">Shantinogor Office ,</p>{" "} */}
              <p className="mb-0">
                <span className="fw-bold">Phone:</span> 01975707071 ,
              </p>
              <p>
                {/* গৌরীপুর অফিসঃ আনু মিয়া টাওয়ার(প্রথমতলা -ইসলামী ব্যাংকের নিচে), গৌরীপুর বাজার, দাউদকান্দি, কুমিল্লা।  */}
                <span className="fw-bold">Address:</span>
                Anu Miah Tower(1st floor below Islami Bank), Gouripur Bazar,
                Doudkandi, Cumilla.
              </p>
            </Col>
            <Col md={4} sm={12} className="p-3">
              <h3 className="mb-0">𝗖𝗼𝘅❜𝘀 𝗕𝗮𝘇𝗮𝗿 𝗢𝗳𝗳𝗶𝗰𝗲</h3>
              {/* <p className="branch-name-text mb-0">Shantinogor Office ,</p>{" "} */}
              <p className="mb-0">
                <span className="fw-bold">Phone:</span> 01986832424 & 01957
                355262
              </p>
              <p>
                <span className="fw-bold">Address:</span>
                1169, Monir Mansion(3rd Floor), Kalur Dokan, Tekpara, Cox’s
                Bazar.
              </p>
            </Col>
            <Col md={4} sm={12} className="p-3 branch-bg-color">
              <h3 className="mb-0">𝗥𝗮𝗻𝗴𝗽𝘂𝗿 𝗢𝗳𝗳𝗶𝗰𝗲:</h3>
              {/* <p className="branch-name-text mb-0">Shantinogor Office ,</p>{" "} */}
              <p className="mb-0">
                <span className="fw-bold">Phone:</span> 01641178674 &
                01323667508
              </p>
              <p>
                <span className="fw-bold">Address:</span>
                Alom Tower(5th Floor), Collage Road, Bikon Mor, Rangpur.
              </p>
            </Col>
            <Col md={4} sm={12} className="p-3">
              <h3 className="mb-0">𝗡𝗮𝗿𝘀𝗵𝗶𝗻𝗴𝗱𝗶 𝗢𝗳𝗳𝗶𝗰𝗲:</h3>
              {/* <p className="branch-name-text mb-0">Shantinogor Office ,</p>{" "} */}
              <p className="mb-0">
                {/* ০১৯২৯১২৪১৩৩, ০১৫১৫৬১৩৪৮১ */}
                <span className="fw-bold">Phone:</span> 01929124133 &
                01515613481
              </p>
              <p>
                <span className="fw-bold">Address:</span>
                206/10 West Brammonadi (Baluchor), Narsingdi Sadar, Narsingdi.
              </p>
            </Col>
            <Col md={4} sm={12} className="p-3 branch-bg-color">
              <h3 className="mb-0">Chandpur 𝗢𝗳𝗳𝗶𝗰𝗲:</h3>
              {/* <p className="branch-name-text mb-0">Shantinogor Office ,</p>{" "} */}
              <p className="mb-0">
                <span className="fw-bold">Phone:</span>
                01716919621
              </p>
              <p>
                <span className="fw-bold">Address:</span>
                Bhuiyan Flat, 5th floor, wireless Bazar, (West of Iqra Model
                Academy ) Chandpur municipality , Chandpur-3600
              </p>
            </Col>
            <Col md={4} sm={12} className="p-3">
              <h3 className="mb-0">Rajshahi 𝗢𝗳𝗳𝗶𝗰𝗲:</h3>
              {/* <p className="branch-name-text mb-0">Shantinogor Office ,</p>{" "} */}
              <p className="mb-0">
                <span className="fw-bold">Phone:</span> 01711138593 ,
                01911971462
              </p>
              <p>
                <span className="fw-bold">Address:</span>
                5th Floor, Gonokpara, Ghoramara, Rajshahi.
              </p>
            </Col>
            <Col md={4} sm={12} className="p-3  branch-bg-color">
              <h3 className="mb-0">Bhola 𝗢𝗳𝗳𝗶𝗰𝗲:</h3>
              {/* <p className="branch-name-text mb-0">Shantinogor Office ,</p>{" "} */}
              <p className="mb-0">
                <span className="fw-bold">Phone:</span> 01712446950 ,
              </p>
              <p>
                <span className="fw-bold">Address:</span>
                Salam Miyazi House, Ideal School Road, 4 No ward,Borhanuddin,
                Bhola.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Branches;
